import './App.css';


function App() {
  return (
    <>
    <div className="overlay"></div>
    <div className="stars" aria-hidden="true"></div>
    <div className="starts2" aria-hidden="true"></div>
    <div className="stars3" aria-hidden="true"></div>
    <main className="main">
     
      <section className="contact">
        <h1 className="title">India Bank Deals coming soon</h1>
        <h2 className="sub-title">Site Under Development</h2>
      </section>
   
      <div className='imgmain'>
        <img className='bannerimg' src="images4/bankingbanner8.png" alt="cartos" />
      </div>
      
    </main>
   
    </>
  );
}

export default App;
